import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import WhiteBox from "./component/WhiteBox";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "../../../components/Image";
import doAPI from "../../../api";

const images = [
  {
    category: "Smash it Camp",
    photos: [
      "./assets/images/smashit/gallery/camp-1.jpg",
      "./assets/images/smashit/gallery/camp-2.jpg",
      "./assets/images/smashit/gallery/camp-3.jpg",
      "./assets/images/smashit/gallery/camp-4.jpg",
      "./assets/images/smashit/gallery/camp-5.jpg",
    ],
  },
  {
    category: "ไทยแลนด์ แชมป์เปี้ยนชิพ สนาม 3 2024",
    photos: [
      "./assets/images/smashit/gallery/championship-1.jpg",
      "./assets/images/smashit/gallery/championship-2.jpg",
      "./assets/images/smashit/gallery/championship-3.jpg",
      "./assets/images/smashit/gallery/championship-4.jpg",
      "./assets/images/smashit/gallery/championship-5.jpg",
      "./assets/images/smashit/gallery/championship-6.jpg",
    ],
  },
  {
    category: "ยาวชนเพื่อความชนะเลิศแห่งประเทศไทย ครั้งที่ 62 ประจำปี 2567",
    photos: [
      "./assets/images/smashit/gallery/youth-1.jpg",
      "./assets/images/smashit/gallery/youth-2.jpg",
      "./assets/images/smashit/gallery/youth-3.jpg",
      "./assets/images/smashit/gallery/youth-4.jpg",
      "./assets/images/smashit/gallery/youth-5.jpg",
      "./assets/images/smashit/gallery/youth-6.jpg",
      "./assets/images/smashit/gallery/youth-7.jpg",
      "./assets/images/smashit/gallery/youth-8.jpg",
    ],
  },
];

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(1);
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    setSelectedImages(images[0].photos);
  }, []);
  const handleImageClick = (categoryIndex, photoIndex) => {
    setSelectedImages(images[categoryIndex].photos);
    setPhotoIndex(photoIndex);
    setIsOpen(true);
  };

  const handleCategoryClick = index => {
    setActiveCategoryIndex(index === activeCategoryIndex ? null : index);
  };

  const fetchAlbums = async () => {
    try {
      const response = await doAPI.getData("gallery/live-view");

      if (response.status) {
        console.log(response.data);
        setAlbums(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching albums:", error);
    }
  };
  useEffect(() => {
    fetchAlbums();
  }, []);

  return (
    <div className="relative bg-[#f2f4ff] py-8">
      <div className="slider-line2 top-[60%]">
        <div className="purpleCapsule shape1"></div>
        <div className="purpleCapsule shape2"></div>
        <div className="purpleCapsule shape3"></div>
      </div>
      <Container className="mx-auto px-4 py-12" id="gallery">
        <div className="col-md-12 heading mb-5 text-center">
          <ScrollAnimation animateIn="fadeInDown">
            <h6 className="text-theme">Gallery</h6>
            <h2 className="headingFont mb-5 font-bold">Explore Our Memorable Moments</h2>
          </ScrollAnimation>
        </div>
        <div className="w-100 mb-[40px] flex flex-wrap items-center justify-center">
          {albums.map((album, categoryIndex) => (
            <div key={categoryIndex} className="p-1">
              <button
                className={`${activeCategoryIndex !== categoryIndex && "opacity-60"} rounded-full border-0 bg-[#445BEF] px-4  py-3 text-white`}
                onClick={() => handleCategoryClick(categoryIndex)}>
                {album.album_name}
              </button>
            </div>
          ))}
        </div>

        {activeCategoryIndex !== null && (
          <div>
            {/* <h4 className="text-center my-4">{images[activeCategoryIndex].category}</h4> */}
            <Row className="g-3">
              {albums[activeCategoryIndex]?.gallery_photos.map((photo, photoIndex) => (
                <div key={photoIndex} className="transition_card w-[100%] sm:w-[50%] md:w-[25%] lg:w-[20%]">
                  <Card
                    className="gallery_wrapper relative mb-0 overflow-hidden"
                    style={{ height: 200, cursor: "pointer" }}
                    onClick={() => handleImageClick(activeCategoryIndex, photoIndex)}>
                    <div className="overlayBg">+</div>
                    <img
                      src={`${albums[0]?.image_path}uploads/${photo?.image_path}/${photo?.image_name}`}
                      className="w-100 h-full object-cover"
                      alt={`Gallery image ${photoIndex + 1}`}
                    />
                  </Card>
                </div>
              ))}
            </Row>
          </div>
        )}

        {isOpen && <WhiteBox imgs={selectedImages} photo_Index={photoIndex} onClose={() => setIsOpen(false)} />}
      </Container>
      <div className="sectionStoryShaps">
        <Image src={"./assets/images/smashit/galleryGraphic1.png"} className=" h-[150px] md:h-[300px]" />
      </div>
    </div>
  );
};

export default Gallery;

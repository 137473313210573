import React, { useRef, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";

const ImageSlider = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error("Autoplay failed:", error);
        });
      }
    }, 1000);
  }, []);

  return (
    <section id="home">
      <div className="image-slider relative overflow-hidden bg-black">
        <div className="w-100 h-100 d-flex align-items-center z-1 position-absolute left-0 top-0 text-center">
          <div className="slider-line2">
            <div className="purpleCapsule shape1"></div>
            <div className="purpleCapsule shape2"></div>
            <div className="purpleCapsule shape3"></div>
          </div>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7 text-center">
                <div className="slider-content mt-20">
                  <h2 className="fw-bolder lh-base mb-3 text-white">
                    <br />
                    <TypeAnimation
                      style={{
                        color: "#fff",
                        // background: "white",
                        fontWeight: "bold",
                      }}
                      sequence={[
                        "Conquer the Court",
                        2000,
                        "Let's Smash It!",
                        2000,
                        () => {
                          console.log("Sequence completed");
                        },
                      ]}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                    />
                    <br />
                    Smash It Tennis Academy
                  </h2>
                  <p className="text-justify text-sm text-white">
                    At Smash It Tennis Academy, we&apos;re all about pushing boundaries and unlocking your full potential on the court. Whether
                    you&apos;re a beginner or a seasoned player, our expert coaches, top-tier facilities, and tailored training programs are here to
                    help you excel. Join us, and let&apos;s smash your goals together!
                  </p>
                  <div className="slider-btn mt-5">
                    <Link to="/player-register" className="customBtn m-2">
                      Register Now!
                    </Link>
                    <Link to="/#programs" className="customBtn active m-2">
                      See Programs
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <video width="100%" muted autoPlay height="auto" className="mb-5 opacity-60" ref={videoRef} controls={false} loop>
          <source src="/assets/videos/smash-it.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* <Slider className="h-100 w-100 opacity-50" {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                className="w-[100%] object-cover h-[100vh]"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider> */}
      </div>
    </section>
  );
};

export default ImageSlider;
